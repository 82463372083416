<template>
    <v-container fluid class="mx-auto">
        <v-dialog v-model="showDialogue" width="500">
            <v-card dark color="rgb(0, 0, 0, 0.8)">
                <v-card-title
                    class="headline"
                    v-show="formSubmitStatus == 'success'"
                >
                    Success!
                </v-card-title>
                <v-card-title
                    class="headline"
                    v-show="formSubmitStatus == 'cancel'"
                >
                    Payment Canceled
                </v-card-title>

                <v-card-text v-show="formSubmitStatus == 'success'">
                    Your classes have been succesfully booked and should be
                    reflected on the scheduling calendar and in your own Google
                    Calendar. If this is not the case, please contact Stephen
                    immediately via email (stephen.xu@catalyst-tutors.com)
                </v-card-text>

                <v-card-text v-show="formSubmitStatus == 'cancel'">
                    For one reason or another, your payment did not go through.
                    Your previous form information has been retained for your
                    convenience.
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-layout>
            <v-row class="mt-5 pa-5">
                <v-col cols="12" md="6">
                    <Calendar />
                </v-col>
                <v-col cols="12" md="6">
                    <ScheduleForm />
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>

<script>
    import Calendar from '../components/Calendar.vue';
    import ScheduleForm from '../components/ScheduleForm.vue';
    import axios from 'axios';

    export default {
        data() {
            return {
                formSubmitStatus: '',
                formSessionID: '',
                formID: '',
            };
        },
        props: {
            source: String,
        },
        computed: {
            showDialogue() {
                if (
                    this.formSubmitStatus == 'success' ||
                    this.formSubmitStatus == 'cancel'
                ) {
                    return true;
                } else {
                    return false;
                }
            },
        },
        components: {
            Calendar,
            ScheduleForm,
        },
        mounted() {
            // console.log('FORM STATUS Schedule', this.$route.query.form);
            this.formSubmitStatus = this.$route.query.form;
            this.formSessionID = this.$route.query.session_id;
            this.formID = this.$route.query.form_id;

            const success_uri = this.$webserver + '/api/user/confirm_session';
            const cancel_uri = this.$webserver + '/api/user/delete_session';
            var payload = {
                session_id: this.formSessionID,
                form_id: this.formID,
            };

            // if success, then try to book with session
            if (this.formSubmitStatus == 'success') {
                console.log('Schedule post', success_uri, payload);

                axios
                    .all([
                        axios.post(success_uri, payload),
                        axios.get(this.$webserver + '/api/calendar'),
                    ])
                    .then(
                        axios.spread((resp1, resp2) => {
                            console.log(
                                'Schedule setting new calendar events!'
                            );
                            this.$store.commit(
                                'form/SET_ALL_EVENTS',
                                resp2.data
                            );
                        })
                    );
            } else if (this.formSubmitStatus == 'cancel') {
                axios.post(cancel_uri, payload);
            }
        },
    };
</script>

<style scoped>
    * {
        font-family: Raleway, Helvetica, Arial, Helvetica, sans-serif;
    }
    .container {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100vh;
    }
    .title {
        color: #aaa;
        font-size: 80px;
        word-break: normal;
        text-shadow: 3px 3px #000000;
    }
</style>
