<template>
    <v-row class="full-height">
        <v-col>
            <v-sheet
                height="10vh"
                dark
                style="background-color: rgba(0, 0, 0, 0)"
                class="text-uppercase font-weight-thin"
            >
                <v-toolbar
                    height="50%"
                    flat
                    dark
                    style="background-color: rgba(0, 0, 0, 0.8)"
                >
                    <v-toolbar-title style="color: #aaa; margin-left: -15px">
                        <v-avatar left v-show="loggedIn" size="2em">
                            <v-img :src="userProfile.picture"></v-img>
                        </v-avatar>

                        <span :style="scheduleTitleMargin">
                            Schedule Lesson
                        </span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-show="loggedIn"
                        color="rgba(150,150,150,.7)"
                        class="mr-2"
                        dark
                        outlined
                        nudge
                        @click="logout"
                    >
                        Log Out
                    </v-btn>
                </v-toolbar>
            </v-sheet>

            <v-sheet
                height="60vh"
                style="
                    background-color: rgba(0, 0, 0, 0);
                    color: #aaa;
                    margin-top: -10px;
                "
            >
                <transition name="fade" mode="out-in">
                    <StepperForm v-if="loggedIn" />

                    <v-btn block large dark @click="login" v-else>
                        <v-icon>mdi-google</v-icon>
                        <span style="padding-right: 5px"></span>
                        Sign in with Google
                    </v-btn>
                </transition>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    // import TimePicker from './TimePicker.vue';
    // import Stripe from './Stripe.vue';
    import StepperForm from './StepperForm.vue';

    export default {
        components: {
            //DatePicker,
            // TimePicker,
            // PayPal,
            StepperForm,
            // Stripe,
        },
        data: () => ({
            //form validity
            valid: false,
            // user details
            email: '',
            additionalEmail: '',
            // session information
            sessionSelect: '',
            sessionTypes: [
                'Private Lesson - Class ($40/Hr, Full Hr Only)',
                'Group Lesson - Class ($30/Hr, Full Hr Only)',
                'Private Lesson - Tutoring ($20/Half Hr)',
                'Group Lesson - Tutoring ($15/Half Hr)',
            ],
            // details about session
            sessionInfo: '',
            // details about a specific class, if applicable
            classSelect: null,
            classes: ['Intro to Python (10 weeks)'],
            // class duration info
            durationSelect: '',
        }),

        computed: {
            ...mapGetters('user', {
                userProfile: 'userProfile',
                loggedIn: 'loggedIn',
            }),
            ...mapGetters('form', {
                selectedDate: 'getSlot',
                selectedEvent: 'getEvent',
            }),
            studentName: function () {
                return this.userProfile.name;
            },
            isClass: function () {
                // console.log('Schedule FORM session select',this.sessionSelect)
                if (this.sessionSelect.includes('Class')) {
                    return true;
                } else {
                    return false;
                }
            },
            durationItems: function () {
                console.log('Schedule FORM session select', this.sessionSelect);
                if (this.sessionSelect.includes('Half Hr Only')) {
                    return ['30 min'];
                } else {
                    return ['30 min', '60 min'];
                }
            },
            availableTimes: function () {
                return ['test1', 'test2'];
            },
            totalCost: function () {
                // accumulate all of the details to send to server
                var post_obj = {};
                post_obj['duration'] = this.durationSelect;
                post_obj['lesson_type'] = this.sessionSelect;
                post_obj['class'];
                this.durationSelect.includes('1 hour');
                var multiplier = 1;
                if (this.durationSelect.includes('1 hour')) {
                    multiplier = 2;
                }

                // calculate cost
                var cost = 0;
                if (this.sessionSelect.includes('Private Lesson')) {
                    cost = multiplier * 20;
                } else if (this.sessionSelect.includes('Group Lesson')) {
                    cost = multiplier * 15;
                }
                return cost + ''; // cast to string
            },
            scheduleTitleMargin: function () {
                if (this.loggedIn) {
                    return 'margin-left: 10px';
                } else {
                    return '';
                }
            },
        },

        methods: {
            submit() {
                this.$v.$touch();
            },
            clear() {
                this.$v.$reset();
                this.name = '';
                this.email = '';
                this.select = null;
                this.checkbox = false;
            },
            ...mapActions('user', {
                login: 'login',
                logout: 'logout',
            }),
        },
    };
</script>

<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
