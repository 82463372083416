<template>
    <v-stepper
        v-model="stepperIdx"
        vertical
        dark
        style="background-color: rgba(0, 0, 0, 0.7)"
    >
        <!-- Block 1 -->
        <v-stepper-step
            :complete="stepperIdx > 1"
            step="1"
            :editable="validateInfo"
            color="#3D74FF"
        >
            Verify your information. {{ shortInfo }}
        </v-stepper-step>

        <v-stepper-content step="1">
            <v-text-field
                class="text-uppercase font-weight-thin mt-2"
                v-model="userName"
                :rules="[(v) => !!v || 'Please enter a name!']"
                label="Name"
                required
                dark
                outlined
                dense
            ></v-text-field>
            <v-text-field
                class="text-uppercase font-weight-thin"
                v-model="userEmail"
                label="Email"
                required
                dark
                disabled
                outlined
                dense
            ></v-text-field>
            <v-btn
                outlined
                @click="
                    stepperIdx = 2;
                    updateState();
                "
                class="mb-5"
                :disabled="!validateInfo"
            >
                Continue
            </v-btn>
        </v-stepper-content>

        <!-- Block 2 -->
        <v-stepper-step
            :complete="stepperIdx > 2"
            step="2"
            :editable="validateType"
            color="#3D74FF"
        >
            Are you scheduling for a class or for help? {{ shortType }}
            <small>For more information, visit Subjects and Pricing.</small>
        </v-stepper-step>

        <v-stepper-content step="2">
            <v-autocomplete
                class="text-uppercase font-weight-thin mt-5"
                :menu-props="{ dark: true }"
                v-model="sessionSelect"
                :items="sessionTypes"
                dense
                label="Class or Help"
                required
                outlined
                dark
            ></v-autocomplete>

            <v-autocomplete
                class="text-uppercase font-weight-thin mt-5"
                :menu-props="{ dark: true }"
                v-model="classSelect"
                v-show="isClass"
                :items="classes"
                dense
                label="Class Name"
                required
                outlined
                dark
            ></v-autocomplete>

            <v-btn
                outlined
                @click="
                    onBtnClick2();
                    updateState();
                "
                class="mb-5"
                :disabled="!validateType"
            >
                Continue
            </v-btn>
        </v-stepper-content>

        <!-- Block 3 -->
        <v-stepper-step
            :complete="stepperIdx > 3"
            step="3"
            :editable="validateNum"
            color="#3D74FF"
        >
            Would you like this to be a group or private lesson? {{ shortNum }}
            <small>Private: 1 Student, Group: 2-3 Students.</small>
        </v-stepper-step>

        <v-stepper-content step="3">
            <v-autocomplete
                style="margin-top: 5px"
                class="text-uppercase font-weight-thin"
                :menu-props="{ dark: true }"
                v-model="classTypeSelect"
                :items="classTypes"
                dense
                label="Session Type"
                required
                outlined
                dark
                @change="updateState()"
            ></v-autocomplete>
            <v-btn
                outlined
                @click="
                    onBtnClick3();
                    updateState();
                "
                class="mb-5"
                :disabled="!validateNum"
            >
                Continue
            </v-btn>
        </v-stepper-content>

        <!-- Block 4 -->
        <v-stepper-step
            :complete="stepperIdx > 4"
            step="4"
            :editable="validateStudentInfo"
            color="#3D74FF"
        >
            Enter student information. {{ shortStudentInfo }}
            <small>
                Students must be over 13 to use own email. Otherwise, use a
                parent email. These emails will be invited to the session.
            </small>
        </v-stepper-step>

        <v-stepper-content step="4">
            <v-row class="ma-0">
                <transition-group name="fade" mode="out-in">
                    <div v-for="(textField, i) in studentInfo" :key="i">
                        <v-row class="mt-0 mr-0" style="margin-left: -10px">
                            <v-col cols="5">
                                <v-text-field
                                    class="text-uppercase font-weight-thin"
                                    v-model="textField.name"
                                    label="Student Name"
                                    :rules="[
                                        (v) => !!v || 'Please enter a name!',
                                    ]"
                                    dark
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="5">
                                <v-text-field
                                    class="text-uppercase font-weight-thin"
                                    v-model="textField.email"
                                    label="Student Email"
                                    dark
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-btn
                                    @click="removeStudentInfo(i)"
                                    icon
                                    style="background-color: #000"
                                    class="white--text"
                                >
                                    <v-icon dark>
                                        mdi-minus-circle-outline
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </transition-group>
            </v-row>
            <v-row class="ml-0 mt-0 mb-5">
                <v-btn
                    @click="addStudentInfo"
                    style="background-color: rgba(50, 50, 50, 0.5)"
                    class="mb-2"
                    :disabled="!canAddStudents"
                >
                    <v-icon dark left>mdi-plus-circle-outline</v-icon>
                    Add Student (
                    <span>{{ numStudentSlots }}</span>
                    )
                </v-btn>
            </v-row>

            <v-row class="ml-0 mt-0 mb-5">
                <v-btn
                    outlined
                    @click="
                        stepperIdx = 5;
                        updateState();
                    "
                    :disabled="!validateStudentInfo"
                >
                    Continue
                </v-btn>
            </v-row>

            <transition type="fade" mode="out-in">
                <v-alert v-show="validateStudentInfo && isGroupSingle" outlined>
                    Because this is a Group Class and you have entered a single
                    student, by clicking CONTINUE, you agree to up to 2 other
                    students joining the class. Additionally, if there is no one
                    else who joins by the start date, you agree to pay for a
                    Private Class.
                </v-alert>
            </transition>
        </v-stepper-content>

        <!-- Block 5 -->
        <v-stepper-step
            :complete="stepperIdx > 5"
            step="5"
            :editable="validateTimes"
            color="#3D74FF"
        >
            Pick a date and time for the session.
            {{ shortTimeInfo }}
            <small>
                All times in America/NY.
                <transition name="fade" mode="out-in">
                    <span v-show="validateStudentInfo">
                        My current date/time: {{ currentTime }}.
                    </span>
                </transition>
            </small>
        </v-stepper-step>

        <v-stepper-content step="5">
            <!-- Private Help and Class Times (Multiple Times per Week) -->
            <!-- For classes, force 1 hour and check weekly + until date -->
            <v-row class="ma-0">
                <transition-group name="fade" mode="out-in">
                    <div v-for="(textField, i) in classTimeInfo" :key="i">
                        <v-row class="mt-0 mr-0" style="margin-left: -10px">
                            <v-col cols="5">
                                <v-text-field
                                    class="text-uppercase font-weight-thin"
                                    v-model="classTimeInfo[i].date"
                                    label="Date"
                                    :rules="[
                                        (v) =>
                                            !!v || 'Click slot from calendar',
                                    ]"
                                    required
                                    dark
                                    outlined
                                    dense
                                    readonly
                                    @click="changeTimeIdx(i)"
                                    @change="onAnywhereClick()"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    class="text-uppercase font-weight-thin"
                                    :menu-props="{ dark: true }"
                                    v-model="classTimeInfo[i].time"
                                    :items="
                                        classTimeInfo[i].availableStartTimes
                                    "
                                    :disabled="!classTimeInfo[i].date"
                                    dense
                                    :rules="[(v) => !!v || 'Item is required']"
                                    label="Start Time"
                                    required
                                    outlined
                                    dark
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <v-autocomplete
                                    class="text-uppercase font-weight-thin"
                                    :menu-props="{ dark: true }"
                                    v-model="classTimeInfo[i].duration"
                                    :items="
                                        classTimeInfo[i].availableDurations[
                                            classTimeInfo[i].time
                                        ]
                                    "
                                    :disabled="!classTimeInfo[i].time"
                                    dense
                                    :rules="[(v) => !!v || 'Item is required']"
                                    label="Duration (min)"
                                    required
                                    outlined
                                    dark
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="1">
                                <v-btn
                                    @click="removeTimeInfo(i)"
                                    icon
                                    style="background-color: #000"
                                    class="white--text"
                                >
                                    <v-icon dark>
                                        mdi-minus-circle-outline
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </transition-group>
            </v-row>

            <v-row class="ml-0 mt-0">
                <v-btn
                    @click="addTimeInfo"
                    style="background-color: rgba(50, 50, 50, 0.5)"
                    class="mb-2"
                    :disabled="!canAddTime"
                >
                    <v-icon dark left>mdi-plus-circle-outline</v-icon>
                    Add Time (
                    <span>{{ numTimeSlots }}</span>
                    )
                </v-btn>
            </v-row>

            <v-row>
                <v-col cols="6">
                    <v-checkbox
                        class="mt-0"
                        style="margin-top: -15px"
                        :disabled="isClass"
                        :input-value="repeatWeeklyForce"
                        :label="`Repeat Weekly?`"
                        v-model="repeatWeekly"
                        value
                    ></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        class="text-uppercase font-weight-thin mt-0"
                        v-model="repeatLastDate"
                        label="Until Date"
                        :disabled="isClass || !repeatWeekly"
                        :readonly="isClass"
                        dark
                        outlined
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-btn
                outlined
                @click="
                    onBtnClick5();
                    updateState();
                "
                class="mb-5"
                :disabled="!validateTimes"
            >
                Continue
            </v-btn>
        </v-stepper-content>

        <!-- Payment block -->
        <v-stepper-step color="#3D74FF" step="6" :editable="validatePay">
            Additional information and send schedule request.
            <small>
                Include typical study subjects and/or propose unlisted lesson
                times. You will receive an email from me when your class is
                confirmed.
            </small>
        </v-stepper-step>
        <v-stepper-content step="6">
            <v-row class="ma-0">
                <v-textarea
                    class="text-uppercase font-weight-thin mt-1"
                    v-model="additionalInfo"
                    label="Additional Info"
                    required
                    dark
                    rows="5"
                    auto-grow
                    outlined
                    dense
                ></v-textarea>
            </v-row>
            <v-row class="ma-0">
                <ScheduleRequest
                    @clear-form="clearForm"
                    :disabled="!validatePay"
                />
                <!-- <Stripe :disabled="!validatePay" /> -->
            </v-row>
        </v-stepper-content>
    </v-stepper>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    // import Stripe from './Stripe.vue';
    import ScheduleRequest from './ScheduleRequest.vue';
    import axios from 'axios';
    // import vClickOutside from 'v-click-outside'; // https://www.npmjs.com/package/vue-outside-events
    // import DatePicker from './DatePicker.vue'
    // https://codepen.io/aznstevy/pen/vYgRQvb?editors=1010

    export default {
        components: {
            // Stripe,
            ScheduleRequest,
        },
        data: () => ({
            // ip info to protect against malicious intents
            userIP: '',
            // is success?
            formSubmitStatus: 0,
            // stepper
            stepperIdx: 1,
            // user info
            userName: '',
            userEmail: '',
            // student details
            studentInfo: [],
            // class type
            classTypeSelect: '',
            // session information
            sessionSelect: '',
            sessionTypes: ['Class (10 weeks, 1 hour each)', 'Help'],
            // details about a specific class, if applicable
            classSelect: null,
            classes: ['Intro to Python'],
            // class duration info
            // classTimeInfo: [],
            additionalInfo: '',
            // time index
            timeIdx: 0,
            // repeat
            repeatWeekly: null,
            repeatLastDate: '',
            // validation
            validInfo: false,
            validType: false,
            validNum: false,
            validStudentInfo: false,
            validTimes: false,
            // time
            currentTime: '',
        }),
        computed: {
            ...mapGetters('user', {
                userProfile: 'userProfile',
                loggedIn: 'loggedIn',
                tokens: 'getTokens',
            }),
            ...mapGetters('form', {
                selectedSlots: 'getSlots',
                selectedEvents: 'getEvents',
                selectedTimeIdx: 'getTimeIdx',
                classTimeInfo: 'getClassTimeInfo',
            }),
            isClass: function () {
                if (this.sessionSelect.toLowerCase().includes('class')) {
                    return true;
                } else {
                    return false;
                }
            },
            isHelp: function () {
                return !this.isClass;
            },
            isGroup: function () {
                if (this.classTypeSelect.toLowerCase().includes('group')) {
                    return true;
                } else {
                    return false;
                }
            },
            isPrivate: function () {
                return !this.isGroup;
            },
            isGroupSingle: function () {
                // console.log(this.isGroup, this.studentInfo.length);
                if (this.isGroup && this.studentInfo.length == 1) {
                    return true;
                } else {
                    return false;
                }
            },
            classTypes: function () {
                if (this.isClass) {
                    return ['Group', 'Private'];
                } else {
                    return ['Private'];
                }
            },
            fullSessionType: function () {
                var numStr = '';
                if (this.isGroup) {
                    numStr = 'group';
                } else {
                    numStr = 'private';
                }

                // class or help
                var typeStr = '';
                if (this.isClass) {
                    typeStr = 'class';
                } else {
                    typeStr = 'help';
                }

                // full
                var fullStr = numStr + '-' + typeStr;
                return fullStr;
            },
            totalCost: function () {
                // accumulate all of the details to send to server
                var post_obj = {};
                post_obj['duration'] = this.durationSelect;
                post_obj['lesson_type'] = this.sessionSelect;
                post_obj['class'];
                this.durationSelect.includes('1 hour');
                var multiplier = 1;
                if (this.durationSelect.includes('1 hour')) {
                    multiplier = 2;
                }

                // calculate cost
                var cost = 0;
                if (this.sessionSelect.includes('Private Lesson')) {
                    cost = multiplier * 20;
                } else if (this.sessionSelect.includes('Group Lesson')) {
                    cost = multiplier * 15;
                }
                return cost + ''; // cast to string
            },
            maxStudents: function () {
                if (this.isGroup) {
                    return 3;
                } else {
                    return 1;
                }
            },
            numStudentSlots: function () {
                // calculate num slots
                var numSlots = Math.max(
                    0,
                    this.maxStudents - this.studentInfo.length
                );
                return numSlots;
            },
            canAddStudents: function () {
                return Boolean(this.numStudentSlots > 0);
            },
            maxTimes: function () {
                if (this.fullSessionType == 'group-class') {
                    return 1;
                } else if (this.fullSessionType == 'private-class') {
                    return 1;
                } else {
                    return 4;
                }
            },
            numTimeSlots: function () {
                var numSlots = Math.max(
                    0,
                    this.maxTimes - this.classTimeInfo.length
                );
                return numSlots;
            },
            canAddTime: function () {
                return Boolean(this.numTimeSlots > 0);
            },
            allOthersCheckBox: function () {
                // if there is only 1 person and group selected, then disable.
                if (
                    this.classTypeSelect.toLowerCase().includes('group') &&
                    this.totalEmails.length == 1
                )
                    return true;
                else {
                    return false;
                }
            },
            repeatWeeklyForce: function () {
                // console.log('REPEAT WEEKLY', this.isClass);

                if (this.isClass) {
                    return true;
                }
                return false;
            },
            // validation
            validateInfo() {
                // console.log('USER NAME', this.userName);
                if (!this.userName) {
                    return false;
                }
                return true;
            },
            validateType() {
                if (!this.sessionSelect) {
                    return false;
                }
                if (this.isClass && !this.classSelect) {
                    return false;
                }
                return true;
            },
            validateNum() {
                if (!this.classTypeSelect) {
                    return false;
                }
                return true;
            },
            validateStudentInfo() {
                // for private
                //console.log('STUDENT INFO', this.studentInfo);
                if (this.isPrivate) {
                    if (this.studentInfo.length != 1) {
                        return false;
                    }
                } else {
                    if (this.studentInfo.length < 1) {
                        return false;
                    }
                }

                for (var i = 0; i < this.studentInfo.length; i++) {
                    var singleInfo = this.studentInfo[i];
                    //console.log(singleInfo);
                    if (!singleInfo.name) {
                        return false;
                    }
                }
                return true;
            },
            validateTimes() {
                if (this.isGroup && this.isClass) {
                    // private class
                    if (this.classTimeInfo.length != 1) {
                        return false;
                    }
                }

                if (this.isPrivate) {
                    if (this.isClass) {
                        // private class
                        if (this.classTimeInfo.length != 1) {
                            return false;
                        }
                    } else {
                        // private help
                        if (this.classTimeInfo.length < 1) {
                            return false;
                        }
                    }

                    // check the actual times
                    for (var i = 0; i < this.classTimeInfo.length; i++) {
                        var singleInfo = this.classTimeInfo[i];
                        // console.log('STEPPER SINGLE INFO', singleInfo);
                        if (
                            !singleInfo.date ||
                            !singleInfo.time ||
                            !singleInfo.duration
                        ) {
                            return false;
                        }
                    }
                }
                return true;
            },
            validatePay: function () {
                return (
                    this.validateInfo &&
                    this.validateType &&
                    this.validateNum &&
                    this.validateStudentInfo &&
                    this.validateTimes
                );
            },
            // response
            formResponse: function () {
                var resp = {
                    user: {
                        name: this.userName,
                        email: this.userEmail,
                    },
                    class_type: this.fullSessionType,
                    students: this.studentInfo,
                    times: this.classTimeInfo,
                };

                return resp;
            },
            formState() {
                var state = {
                    userName: this.userName,
                    userEmail: this.userEmail,
                    userID: this.userProfile.id,
                    userIP: this.userIP,
                    studentInfo: this.studentInfo,
                    classTypeSelect: this.classTypeSelect,
                    sessionSelect: this.sessionSelect,
                    classSelect: this.classSelect,
                    durationSelect: this.durationSelect,
                    timeSelect: this.timeSelect,
                    repeatWeekly: this.repeatWeekly,
                    repeatLastDate: this.repeatLastDate,
                    classTimeInfo: this.classTimeInfo,
                    fullSessionType: this.fullSessionType,
                    additionalInfo: this.additionalInfo,
                    tokens: this.tokens,
                };
                return state;
            },
            // some other extras
            shortInfo() {
                if (this.validateInfo && this.stepperIdx > 1) {
                    return '(' + this.userName + ')';
                } else {
                    return '';
                }
            },
            shortType() {
                if (this.validateType && this.stepperIdx > 2) {
                    if (this.isClass) {
                        return '(Class)';
                    } else {
                        return '(Help)';
                    }
                } else {
                    return '';
                }
            },
            shortNum() {
                if (this.validateNum && this.stepperIdx > 3) {
                    if (this.isGroup) {
                        return '(Group)';
                    } else {
                        return '(Private)';
                    }
                } else {
                    return '';
                }
            },
            shortStudentInfo() {
                if (this.validateStudentInfo && this.stepperIdx > 4) {
                    return '(' + this.studentInfo.length + ')';
                } else {
                    return '';
                }
            },
            shortTimeInfo() {
                if (this.validateTimes && this.stepperIdx > 5) {
                    if (this.isGroup && this.isClass) {
                        return '(1)';
                    } else {
                        return '(' + this.classTimeInfo.length + ')';
                    }
                } else {
                    return '';
                }
            },
        },
        methods: {
            ...mapActions('user', {
                login: 'login',
                logout: 'logout',
            }),
            addStudentInfo() {
                this.studentInfo.push({
                    name: '',
                    email: '',
                });
            },
            addTimeInfo() {
                // add new
                this.$store.commit('form/ADD_TIME_INFO', {
                    date: '',
                    time: '',
                    duration: '',
                    slot: '',
                    availableStartTimes: [],
                    availableDurations: [],
                });
            },
            removeStudentInfo(index) {
                this.studentInfo.splice(index, 1);
            },
            removeTimeInfo(index) {
                // remove time
                this.$store.commit('form/REMOVE_TIME_INFO', index);
            },
            onBtnClick2() {
                if (this.isClass) {
                    this.repeatWeekly = true;
                }
                this.stepperIdx = 3;
            },
            onBtnClick3() {
                this.stepperIdx = 4;

                // also handle student list if changed to private vs group
                this.studentInfo = this.studentInfo.slice(0, this.maxStudents);
            },
            onBtnClick5() {
                this.stepperIdx = 6;

                // change index to something impossible so no miss-click
                this.$store.commit('form/SET_TIME_IDX', -2);
            },
            updateState() {
                this.$store.commit('form/SET_FORM_STATE', this.formState);
            },
            setFormState(formData) {
                this.userName = formData.userName;
                this.userEmail = formData.userEmail;
                this.userID = formData.userID;
                this.studentInfo = formData.studentInfo;
                this.classTypeSelect = formData.classTypeSelect;
                this.sessionSelect = formData.sessionSelect;
                this.classSelect = formData.classSelect;
                this.repeatWeekly = formData.repeatWeekly;
                // this.repeatLastDate = formData.repeatLastDate;
                this.additionalInfo = formData.additionalInfo;
                // this.classTimeInfo = formData.classTimeInfo; // through vuex
                // this.fullSessionType = formData.fullSessionType; //computed
            },
            updateTime() {
                const d = new Date();
                // console.log('DATE OUTPUT', d);
                const year = d.getFullYear();
                const month = d.getMonth() + 1;
                const day = d.getDate();
                const hours = d.getHours();
                var ampm = '';
                if (hours > 12) {
                    ampm = 'PM';
                } else {
                    ampm = 'AM';
                }
                var hours12 = hours % 12;
                hours12 = (hours12 < 10 ? '0' : '') + hours12;

                const minutes =
                    (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();

                // string
                const timeStr =
                    month +
                    '/' +
                    day +
                    '/' +
                    year +
                    ', ' +
                    hours12 +
                    ':' +
                    minutes +
                    ' ' +
                    ampm;

                // console.log('TIME STRING', timeStr);

                this.currentTime = timeStr;
            },
            changeTimeIdx(index) {
                // change current time idx
                this.$store.commit('form/SET_TIME_IDX', index);
            },
            clearForm: function (val) {
                if (val) {
                    this.studentInfo = [];
                    this.classTypeSelect = '';
                    this.sessionSelect = '';
                    this.classSelect = null;
                    this.durationSelect = '';
                    this.repeatWeekly = '';
                    // this.repeatLastDate = '';
                    this.classTimeInfo = [];
                    this.additionalInfo = '';

                    this.clearForm = false;

                    // set new form state
                    this.$store.commit('form/CLEAR_FORM');
                    // this.updateState();
                }
            },
        },
        mounted: function () {
            // initialize stuff that requires the state
            // console.log('FORM STATUS STEPPER', this.$route.query.form);
            this.formSubmitStatus = this.$route.query.form;
            this.userName = this.$store.state.user.userProfile.name;
            this.userEmail = this.$store.state.user.userProfile.email;
            this.userID = this.$store.state.user.userProfile.id;
            this.interval = setInterval(this.updateTime, 1000);
            var accessToken = this.tokens['accessToken'];

            axios
                .all([
                    axios.get('https://www.cloudflare.com/cdn-cgi/trace'),
                    axios.get(this.$webserver + '/api/user/get_form', {
                        params: {
                            access_token: accessToken,
                            user_id: this.userID,
                        },
                    }),
                ])
                .then(
                    axios.spread((resp1, resp2) => {
                        // resp 1
                        var ipResponse = resp1.data;
                        this.userIP = ipResponse.substring(
                            ipResponse.search('ip=') + 3,
                            ipResponse.search('ts=')
                        );

                        // resp2
                        if (
                            !!resp2.data &&
                            Object.keys(resp2.data).length !== 0
                        ) {
                            // console.log('SETTING STATE', resp.data);
                            this.$store.commit(
                                'form/SET_FORM_STATE',
                                resp2.data
                            );
                            this.setFormState(resp2.data);
                        }
                    })
                );
        },
        beforeDestroy() {
            clearInterval(this.interval);
        },
        watch: {
            additionalInfo: function (val) {
                // update additional info in form
                this.$store.commit('form/SET_ADDITIONAL_INFO', val);
            },
            classTimeInfo: {
                deep: true,
                handler(val) {
                    if (this.isClass && val.length == 1) {
                        var dt = new Date(val[0].slot.end);
                        // console.log('Date time', dt, val[0]);
                        var new_date = new Date(
                            dt.setDate(dt.getDate() + 9 * 7)
                        );
                        this.repeatLastDate = new_date.toDateString();
                    }
                },
            },
        },
    };
</script>

<style lang="scss" scoped>
    * {
        font-weight: 100;
        line-height: 1.5em;
    }
</style>
