var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper',{staticStyle:{"background-color":"rgba(0, 0, 0, 0.7)"},attrs:{"vertical":"","dark":""},model:{value:(_vm.stepperIdx),callback:function ($$v) {_vm.stepperIdx=$$v},expression:"stepperIdx"}},[_c('v-stepper-step',{attrs:{"complete":_vm.stepperIdx > 1,"step":"1","editable":_vm.validateInfo,"color":"#3D74FF"}},[_vm._v(" Verify your information. "+_vm._s(_vm.shortInfo)+" ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-text-field',{staticClass:"text-uppercase font-weight-thin mt-2",attrs:{"rules":[function (v) { return !!v || 'Please enter a name!'; }],"label":"Name","required":"","dark":"","outlined":"","dense":""},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c('v-text-field',{staticClass:"text-uppercase font-weight-thin",attrs:{"label":"Email","required":"","dark":"","disabled":"","outlined":"","dense":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('v-btn',{staticClass:"mb-5",attrs:{"outlined":"","disabled":!_vm.validateInfo},on:{"click":function($event){_vm.stepperIdx = 2;
                _vm.updateState();}}},[_vm._v(" Continue ")])],1),_c('v-stepper-step',{attrs:{"complete":_vm.stepperIdx > 2,"step":"2","editable":_vm.validateType,"color":"#3D74FF"}},[_vm._v(" Are you scheduling for a class or for help? "+_vm._s(_vm.shortType)+" "),_c('small',[_vm._v("For more information, visit Subjects and Pricing.")])]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-autocomplete',{staticClass:"text-uppercase font-weight-thin mt-5",attrs:{"menu-props":{ dark: true },"items":_vm.sessionTypes,"dense":"","label":"Class or Help","required":"","outlined":"","dark":""},model:{value:(_vm.sessionSelect),callback:function ($$v) {_vm.sessionSelect=$$v},expression:"sessionSelect"}}),_c('v-autocomplete',{directives:[{name:"show",rawName:"v-show",value:(_vm.isClass),expression:"isClass"}],staticClass:"text-uppercase font-weight-thin mt-5",attrs:{"menu-props":{ dark: true },"items":_vm.classes,"dense":"","label":"Class Name","required":"","outlined":"","dark":""},model:{value:(_vm.classSelect),callback:function ($$v) {_vm.classSelect=$$v},expression:"classSelect"}}),_c('v-btn',{staticClass:"mb-5",attrs:{"outlined":"","disabled":!_vm.validateType},on:{"click":function($event){_vm.onBtnClick2();
                _vm.updateState();}}},[_vm._v(" Continue ")])],1),_c('v-stepper-step',{attrs:{"complete":_vm.stepperIdx > 3,"step":"3","editable":_vm.validateNum,"color":"#3D74FF"}},[_vm._v(" Would you like this to be a group or private lesson? "+_vm._s(_vm.shortNum)+" "),_c('small',[_vm._v("Private: 1 Student, Group: 2-3 Students.")])]),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-autocomplete',{staticClass:"text-uppercase font-weight-thin",staticStyle:{"margin-top":"5px"},attrs:{"menu-props":{ dark: true },"items":_vm.classTypes,"dense":"","label":"Session Type","required":"","outlined":"","dark":""},on:{"change":function($event){return _vm.updateState()}},model:{value:(_vm.classTypeSelect),callback:function ($$v) {_vm.classTypeSelect=$$v},expression:"classTypeSelect"}}),_c('v-btn',{staticClass:"mb-5",attrs:{"outlined":"","disabled":!_vm.validateNum},on:{"click":function($event){_vm.onBtnClick3();
                _vm.updateState();}}},[_vm._v(" Continue ")])],1),_c('v-stepper-step',{attrs:{"complete":_vm.stepperIdx > 4,"step":"4","editable":_vm.validateStudentInfo,"color":"#3D74FF"}},[_vm._v(" Enter student information. "+_vm._s(_vm.shortStudentInfo)+" "),_c('small',[_vm._v(" Students must be over 13 to use own email. Otherwise, use a parent email. These emails will be invited to the session. ")])]),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('v-row',{staticClass:"ma-0"},[_c('transition-group',{attrs:{"name":"fade","mode":"out-in"}},_vm._l((_vm.studentInfo),function(textField,i){return _c('div',{key:i},[_c('v-row',{staticClass:"mt-0 mr-0",staticStyle:{"margin-left":"-10px"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"text-uppercase font-weight-thin",attrs:{"label":"Student Name","rules":[
                                    function (v) { return !!v || 'Please enter a name!'; } ],"dark":"","outlined":"","dense":""},model:{value:(textField.name),callback:function ($$v) {_vm.$set(textField, "name", $$v)},expression:"textField.name"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"text-uppercase font-weight-thin",attrs:{"label":"Student Email","dark":"","outlined":"","dense":""},model:{value:(textField.email),callback:function ($$v) {_vm.$set(textField, "email", $$v)},expression:"textField.email"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"white--text",staticStyle:{"background-color":"#000"},attrs:{"icon":""},on:{"click":function($event){return _vm.removeStudentInfo(i)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus-circle-outline ")])],1)],1)],1)],1)}),0)],1),_c('v-row',{staticClass:"ml-0 mt-0 mb-5"},[_c('v-btn',{staticClass:"mb-2",staticStyle:{"background-color":"rgba(50, 50, 50, 0.5)"},attrs:{"disabled":!_vm.canAddStudents},on:{"click":_vm.addStudentInfo}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" Add Student ( "),_c('span',[_vm._v(_vm._s(_vm.numStudentSlots))]),_vm._v(" ) ")],1)],1),_c('v-row',{staticClass:"ml-0 mt-0 mb-5"},[_c('v-btn',{attrs:{"outlined":"","disabled":!_vm.validateStudentInfo},on:{"click":function($event){_vm.stepperIdx = 5;
                    _vm.updateState();}}},[_vm._v(" Continue ")])],1),_c('transition',{attrs:{"type":"fade","mode":"out-in"}},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.validateStudentInfo && _vm.isGroupSingle),expression:"validateStudentInfo && isGroupSingle"}],attrs:{"outlined":""}},[_vm._v(" Because this is a Group Class and you have entered a single student, by clicking CONTINUE, you agree to up to 2 other students joining the class. Additionally, if there is no one else who joins by the start date, you agree to pay for a Private Class. ")])],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.stepperIdx > 5,"step":"5","editable":_vm.validateTimes,"color":"#3D74FF"}},[_vm._v(" Pick a date and time for the session. "+_vm._s(_vm.shortTimeInfo)+" "),_c('small',[_vm._v(" All times in America/NY. "),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.validateStudentInfo),expression:"validateStudentInfo"}]},[_vm._v(" My current date/time: "+_vm._s(_vm.currentTime)+". ")])])],1)]),_c('v-stepper-content',{attrs:{"step":"5"}},[_c('v-row',{staticClass:"ma-0"},[_c('transition-group',{attrs:{"name":"fade","mode":"out-in"}},_vm._l((_vm.classTimeInfo),function(textField,i){return _c('div',{key:i},[_c('v-row',{staticClass:"mt-0 mr-0",staticStyle:{"margin-left":"-10px"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"text-uppercase font-weight-thin",attrs:{"label":"Date","rules":[
                                    function (v) { return !!v || 'Click slot from calendar'; } ],"required":"","dark":"","outlined":"","dense":"","readonly":""},on:{"click":function($event){return _vm.changeTimeIdx(i)},"change":function($event){return _vm.onAnywhereClick()}},model:{value:(_vm.classTimeInfo[i].date),callback:function ($$v) {_vm.$set(_vm.classTimeInfo[i], "date", $$v)},expression:"classTimeInfo[i].date"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{staticClass:"text-uppercase font-weight-thin",attrs:{"menu-props":{ dark: true },"items":_vm.classTimeInfo[i].availableStartTimes,"disabled":!_vm.classTimeInfo[i].date,"dense":"","rules":[function (v) { return !!v || 'Item is required'; }],"label":"Start Time","required":"","outlined":"","dark":""},model:{value:(_vm.classTimeInfo[i].time),callback:function ($$v) {_vm.$set(_vm.classTimeInfo[i], "time", $$v)},expression:"classTimeInfo[i].time"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{staticClass:"text-uppercase font-weight-thin",attrs:{"menu-props":{ dark: true },"items":_vm.classTimeInfo[i].availableDurations[
                                        _vm.classTimeInfo[i].time
                                    ],"disabled":!_vm.classTimeInfo[i].time,"dense":"","rules":[function (v) { return !!v || 'Item is required'; }],"label":"Duration (min)","required":"","outlined":"","dark":""},model:{value:(_vm.classTimeInfo[i].duration),callback:function ($$v) {_vm.$set(_vm.classTimeInfo[i], "duration", $$v)},expression:"classTimeInfo[i].duration"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"white--text",staticStyle:{"background-color":"#000"},attrs:{"icon":""},on:{"click":function($event){return _vm.removeTimeInfo(i)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus-circle-outline ")])],1)],1)],1)],1)}),0)],1),_c('v-row',{staticClass:"ml-0 mt-0"},[_c('v-btn',{staticClass:"mb-2",staticStyle:{"background-color":"rgba(50, 50, 50, 0.5)"},attrs:{"disabled":!_vm.canAddTime},on:{"click":_vm.addTimeInfo}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" Add Time ( "),_c('span',[_vm._v(_vm._s(_vm.numTimeSlots))]),_vm._v(" ) ")],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-checkbox',{staticClass:"mt-0",staticStyle:{"margin-top":"-15px"},attrs:{"disabled":_vm.isClass,"input-value":_vm.repeatWeeklyForce,"label":"Repeat Weekly?","value":""},model:{value:(_vm.repeatWeekly),callback:function ($$v) {_vm.repeatWeekly=$$v},expression:"repeatWeekly"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"text-uppercase font-weight-thin mt-0",attrs:{"label":"Until Date","disabled":_vm.isClass || !_vm.repeatWeekly,"readonly":_vm.isClass,"dark":"","outlined":"","dense":""},model:{value:(_vm.repeatLastDate),callback:function ($$v) {_vm.repeatLastDate=$$v},expression:"repeatLastDate"}})],1)],1),_c('v-btn',{staticClass:"mb-5",attrs:{"outlined":"","disabled":!_vm.validateTimes},on:{"click":function($event){_vm.onBtnClick5();
                _vm.updateState();}}},[_vm._v(" Continue ")])],1),_c('v-stepper-step',{attrs:{"color":"#3D74FF","step":"6","editable":_vm.validatePay}},[_vm._v(" Additional information and send schedule request. "),_c('small',[_vm._v(" Include typical study subjects and/or propose unlisted lesson times. You will receive an email from me when your class is confirmed. ")])]),_c('v-stepper-content',{attrs:{"step":"6"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-textarea',{staticClass:"text-uppercase font-weight-thin mt-1",attrs:{"label":"Additional Info","required":"","dark":"","rows":"5","auto-grow":"","outlined":"","dense":""},model:{value:(_vm.additionalInfo),callback:function ($$v) {_vm.additionalInfo=$$v},expression:"additionalInfo"}})],1),_c('v-row',{staticClass:"ma-0"},[_c('ScheduleRequest',{attrs:{"disabled":!_vm.validatePay},on:{"clear-form":_vm.clearForm}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }