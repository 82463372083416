var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"full-height"},[_c('v-col',[_c('v-sheet',{staticStyle:{"background-color":"rgba(0, 0, 0, 0)"},attrs:{"height":"10vh","dark":""}},[_c('v-toolbar',{staticClass:"text-uppercase font-weight-thin",staticStyle:{"background-color":"rgba(0, 0, 0, 0.8)"},attrs:{"height":"50%","flat":"","dark":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-0"},on:{"click":_vm.setToday}},[_vm._v(" Today ")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-0"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-left")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',{staticStyle:{"color":"#aaa"}},[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-0"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-0"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{attrs:{"dark":""}},[_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',{staticClass:"text-uppercase font-weight-thin"},[_vm._v(" WEEK ")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',{staticClass:"text-uppercase font-weight-thin"},[_vm._v(" Month ")])],1)],1)],1)],1)],1),_c('v-sheet',{staticStyle:{"background-color":"rgba(0, 0, 0, 0.7)"},attrs:{"height":"80vh"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-calendar',{ref:"calendar",staticClass:"text-uppercase font-weight-thin",staticStyle:{"background-color":"rgba(40, 40, 40, 0.2)","color":"#aaa","border-color":"rgba(0, 0, 0, 0)","outline-color":"rgba(0, 0, 0, 0)","border":"none","border-right":"none","border-left":"none","border-bottom":"none","border-top":"none"},attrs:{"color":"rgba(100, 100, 100, .5)","events":_vm.calEvents,"event-color":_vm.getEventColor,"type":_vm.type,"first-interval":"8","interval-count":"16","event-overlap-mode":"column","event-ripple":false,"interval-height":80,"transition":"fade","dark":""},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [_c('v-tooltip',{attrs:{"top":"","open-delay":200,"close-delay":200,"open-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-sheet',_vm._g(_vm._b({staticClass:"fill-height text-wrap",style:({
                                        'background-color': event.color,
                                    }),on:{"click":function($event){return _vm.updateEvent(event)}}},'v-sheet',attrs,false),on),[_vm._v(" "+_vm._s(event.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(event.description))])])]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }