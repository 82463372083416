<template>
    <div>
        <v-dialog v-model="showDialogue" width="500">
            <v-card dark color="rgb(0, 0, 0, 0.8)">
                <v-card-title class="headline" v-show="!error">
                    Email has been sent!
                </v-card-title>
                <v-card-title class="headline wrap-text" v-show="!!error">
                    You are being rate limited. Please wait
                    {{ cooldown }} seconds and try again.
                </v-card-title>
                <v-card-text v-show="!error">
                    I will confirm your booking via the email you used to sign
                    up. Feel free to contact me at
                    stephen.xu@catalyst-tutors.com if you have questions before
                    then. Additionally, I may reach out to ask for clarification
                    about your student's situation.
                </v-card-text>
                <v-card-text v-show="!!error">
                    You are most likely not trying to be malicious, but this
                    rate limit is a just-in-case.
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-btn
            color="#3D74FF"
            dark
            outlined
            @click="onSubmit()"
            :loading="isLoading"
            :disabled="disabled || submitDisabled || isLoading"
        >
            <span>Send Email</span>
        </v-btn>
    </div>
</template>

<script>
    import axios from 'axios';
    import { mapGetters } from 'vuex';

    export default {
        props: {
            disabled: {
                required: true,
                type: Boolean,
            },
        },
        data() {
            return {
                isLoading: false,
                isSubmitted: false,
                showDialogue: false,
                error: '',
                cooldown: 0,
                submitDisabled: false,
            };
        },
        computed: {
            ...mapGetters('form', {
                finalFormState: 'getFormState',
            }),
        },
        methods: {
            onSubmit: function () {
                // axios request to email information
                this.isLoading = true;
                const payload = this.finalFormState;
                axios
                    .post(
                        this.$webserver + '/api/user/send_info_email',
                        payload
                    )
                    .then((resp) => {
                        var data = resp.data;
                        this.error = data.error;
                        this.cooldown = Math.round(data.cooldown);
                        this.isSubmitted = !this.error;
                        this.isLoading = false;

                        if (!this.error) {
                            this.submitDisabled = true;
                            this.showDialogue = true;
                            this.$emit('clear-form', true);
                        } else {
                            this.submitDisabled = false;
                            this.showDialogue = true;
                            this.$emit('clear-form', false);
                        }
                    });
            },
        },
    };
</script>

<style lang="scss" scoped></style>
