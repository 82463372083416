<template>
    <v-row class="full-height">
        <v-col>
            <v-sheet
                height="10vh"
                dark
                style="background-color: rgba(0, 0, 0, 0)"
            >
                <v-toolbar
                    height="50%"
                    class="text-uppercase font-weight-thin"
                    flat
                    dark
                    style="background-color: rgba(0, 0, 0, 0.8)"
                >
                    <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-0"
                        @click="setToday"
                    >
                        Today
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn fab text small color="grey darken-0" @click="prev">
                        <v-icon small>mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-toolbar-title v-if="$refs.calendar" style="color: #aaa">
                        {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-btn fab text small color="grey darken-0" @click="next">
                        <v-icon small>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                outlined
                                color="grey darken-0"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <span>{{ typeToLabel[type] }}</span>
                                <v-icon right>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>

                        <v-list dark>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title
                                    class="text-uppercase font-weight-thin"
                                >
                                    WEEK
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                                <v-list-item-title
                                    class="text-uppercase font-weight-thin"
                                >
                                    Month
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar>
            </v-sheet>

            <v-sheet height="80vh" style="background-color: rgba(0, 0, 0, 0.7)">
                <transition name="fade" mode="out-in">
                    <v-calendar
                        class="text-uppercase font-weight-thin"
                        ref="calendar"
                        v-model="focus"
                        color="rgba(100, 100, 100, .5)"
                        :events="calEvents"
                        :event-color="getEventColor"
                        :type="type"
                        first-interval="8"
                        interval-count="16"
                        event-overlap-mode="column"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        :event-ripple="false"
                        :interval-height="80"
                        transition="fade"
                        style="
                            background-color: rgba(40, 40, 40, 0.2);
                            color: #aaa;
                            border-color: rgba(0, 0, 0, 0);
                            outline-color: rgba(0, 0, 0, 0);
                            border: none;
                            border-right: none;
                            border-left: none;
                            border-bottom: none;
                            border-top: none;
                        "
                        dark
                    >
                        <template v-slot:event="{ event }">
                            <!-- for slots, events go on the actual calendar -->
                            <v-tooltip
                                top
                                :open-delay="200"
                                :close-delay="200"
                                :open-on-click="true"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-sheet
                                        v-bind="attrs"
                                        v-on="on"
                                        :style="{
                                            'background-color': event.color,
                                        }"
                                        class="fill-height text-wrap"
                                        @click="updateEvent(event)"
                                    >
                                        {{ event.name }}
                                    </v-sheet>
                                </template>
                                <span>{{ event.description }}</span>
                            </v-tooltip>
                        </template>
                    </v-calendar>
                </transition>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script>
    import axios from 'axios';
    import { mapGetters } from 'vuex';
    // import { dragscroll } from 'vue-dragscroll';
    // import store from '@/store';

    export default {
        data: () => ({
            focus: '',
            type: 'week',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
            },
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            colors: ['rgba(61, 116, 255, 0)'],
            names: ['Meeting', 'Holiday', 'PTO'],
            // events: [],
            // allEvents: [],
        }),
        mounted() {
            this.$refs.calendar.checkChange();
            axios.get(this.$webserver + '/api/calendar').then((response) => {
                this.$store.commit('form/SET_ALL_EVENTS', response.data);
            });
        },
        methods: {
            viewDay({ date }) {
                this.focus = date;
                this.type = 'day';
            },
            getEventColor(event) {
                return event.color;
            },
            setToday() {
                this.focus = '';
            },
            prev() {
                this.$refs.calendar.prev();
            },
            next() {
                this.$refs.calendar.next();
            },
            showEvent({ nativeEvent, event }) {
                const open = () => {
                    this.selectedEvent = event;
                    this.selectedElement = nativeEvent.target;
                    setTimeout(() => {
                        this.selectedOpen = true;
                    }, 10);
                };

                if (this.selectedOpen) {
                    this.selectedOpen = false;
                    setTimeout(open, 10);
                } else {
                    open();
                }

                nativeEvent.stopPropagation();
            },
            updateEvent(event) {
                // console.log('SETTING');
                // console.log('event', event);
                if (event && !event['name']) {
                    // check if slot already exists
                    for (
                        var i = 0;
                        i < this.currentState.classTimeInfo.length;
                        i++
                    ) {
                        var slot = this.currentState.classTimeInfo[i].slot;

                        if (slot === event) {
                            return;
                        }
                    }

                    // check if time is valid
                    var tzStartDate = new Date(
                        new Date(event.start).toLocaleString('en-US', {
                            timeZone: 'America/New_York',
                        })
                    );

                    var nowTime = new Date(Date.now());
                    nowTime = new Date(
                        nowTime.toLocaleString('en-US', {
                            timeZone: 'America/New_York',
                        })
                    );
                    // console.log('TIMES', tzStartDate, nowTime);
                    if (tzStartDate < nowTime) {
                        return;
                    }

                    // commit to store
                    this.$store.commit('form/SET_DATE', event);
                }
            },
        },
        computed: {
            ...mapGetters('user', {
                userProfile: 'userProfile',
                loggedIn: 'loggedIn',
            }),
            ...mapGetters('form', {
                currentState: 'getState',
                allEvents: 'getAllEvents',
            }),
            calEvents: function () {
                var filteredEvents = [];
                var event = null;

                if (this.type == 'week' || this.type == 'day') {
                    // if nothing selected, return full
                    if (!this.currentState.classTypeSelect) {
                        return this.allEvents;
                    }

                    // filter available
                    for (
                        var wdIdx = 0;
                        wdIdx < this.allEvents.length;
                        wdIdx++
                    ) {
                        event = this.allEvents[wdIdx];

                        var cleanedClassType = this.currentState.classTypeSelect.toLowerCase();

                        if (
                            cleanedClassType.includes('group') &&
                            event.class_type.includes('group')
                        ) {
                            filteredEvents.push(event);
                        } else if (
                            cleanedClassType.includes('private') &&
                            event.class_type.includes('private')
                        ) {
                            filteredEvents.push(event);
                        }
                    }
                } else {
                    // show events pertaining only to user for month view.
                    for (var i = 0; i < this.allEvents.length; i++) {
                        event = this.allEvents[i];
                        var userEmail = this.userProfile.email.toLowerCase();

                        for (var j = 0; j < event.attendees; j++) {
                            var checkEmail = event.attendees[
                                j
                            ].email.toLowerCase();

                            if (userEmail === checkEmail) {
                                filteredEvents.push(event);
                                break;
                            }
                        }
                    }
                }

                // add new events to filtered events
                filteredEvents.push(...this.currentState.newEventsPreview);
                return filteredEvents;
            },
        },
    };
</script>

<style scoped>
    ::v-deep .theme--dark.v-calendar-daily .v-calendar-daily__day-interval {
        background-color: rgba(0, 0, 0, 0.8);
        border-top: rgba(100, 100, 100, 0.6) 1px solid;
        border-radius: 0 !important;
        margin-right: 0px !important;
        font-family: Raleway, Helvetica, Arial, Helvetica, sans-serif;
    }
    ::v-deep .theme--dark.v-calendar-weekly .v-calendar-weekly__day {
        font-family: Raleway, Helvetica, Arial, Helvetica, sans-serif;
        border-right: rgba(100, 100, 100, 0.6) 1px solid;
        border-bottom: rgba(100, 100, 100, 0.6) 1px solid;
        border-radius: 0 !important;
        margin-right: 0px !important;
        color: #aaa;
    }

    ::v-deep .theme--dark.v-calendar-events .v-event-timed {
        font-family: Raleway, Helvetica, Arial, Helvetica, sans-serif;
        border: rgba(100, 100, 100, 0) 1px solid !important;
        margin-right: 0px !important;
        border-radius: 0 !important;
    }

    ::v-deep .theme--dark.v-calendar-weekly .v-calendar-weekly__head-weekday {
        border: rgba(100, 100, 100, 0) 1px solid !important;
        color: #aaa;
        margin-right: 0px !important;
        border-radius: 0 !important;
    }

    ::v-deep .v-event-timed-container {
        border-radius: 0 !important;
        margin-right: 0px !important;
    }
</style>
